import { AutobizSectionTitle, AutobizIcon, AutobizButton } from 'autobiz-strap';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { t } from 'autobiz-translate';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Col, Container, FormGroup, Label, Row, Tooltip } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import {
    getUserDefaultPreferencesUseCase,
    getUserPreferencesUseCase,
} from '../../../../hexagon/usecases/getUserPreferences/getUserPreferencesUseCase';
import { getPreferencesSelector } from '../../view-models-generators/preferencesSelectors';
import Header from '../Header/Header';
import { Loader } from '../Loader/Loader';
import { updateUserPreferencesUseCase } from '../../../../hexagon/usecases/updateUserPreferences/updateUserPreferencesUseCase';
import useDeviceType from '../../../../utils/useDeviceType';
import { getUserSelector } from '../../view-models-generators/userSelector';

import AuthSpinner from '../AuthSpinner/AuthSpinner';
import { countryToCurrency } from '../../../../utils/countryToCurrency';

const preferencesTable = [
    { code: 'storageCost', unit: 'currency' },
    { code: 'marginEuro', unit: 'currency' },
    { code: 'marginPercentage', unit: '%' },
    { code: 'adminCosts', unit: 'currency' },
    { code: 'reviewCosts', unit: 'currency' },
    { code: 'warrantyFee1', unit: 'currency' },
    { code: 'warrantyFee2', unit: 'currency' },
    { code: 'warrantyFee3', unit: 'currency' },
    { code: 'interestRate', unit: '%' },
    { code: 'commercialExpenses', unit: '%' },
];

interface IPreferenceRow {
    code: string;
    unit: string;
}

const normalizeFloat = (value: any) => {
    const explodedFloat = value.split('.');

    if (explodedFloat.length === 2) {
        if (explodedFloat[1].length > 2) {
            return `${explodedFloat[0]}.${explodedFloat[1].substr(0, 2)}`;
        }
    }
    return value;
};

const PreferenceRow: React.FC<IPreferenceRow> = ({ code, unit }) => {
    const [open, setOpen] = useState<boolean>(false);

    const { user: userData } = useSelector(getUserSelector);

    return (
        <Field name={code} parse={normalizeFloat}>
            {({ input, meta }) => (
                <>
                    <FormGroup
                        className={meta.error && meta.touched ? 'row form-group-error' : 'row'}
                    >
                        <div className="col-sm-6 col-md-9 col-lg-10">
                            <Label>
                                {code.startsWith('warrantyFee') ? (
                                    <span className="pl-3">•</span>
                                ) : (
                                    ''
                                )}{' '}
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t(code) || '',
                                    }}
                                />
                            </Label>
                            <span id={`tooltip_${code}`} className="ml-2">
                                <AutobizIcon name="InfoCircle" size={15} />
                            </span>
                            <Tooltip
                                placement="top"
                                isOpen={open}
                                target={`tooltip_${code}`}
                                toggle={() => setOpen(!open)}
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t(`${code}Info`) || '',
                                    }}
                                />
                            </Tooltip>
                        </div>

                        <Col sm="6" md={3} lg={2}>
                            <div className="input-group">
                                <input
                                    type="text"
                                    {...input}
                                    value={input.value}
                                    className={`form-control text-right 
                                        ${meta.error && meta.touched && 'error-control'}`}
                                    onKeyPress={(event) => {
                                        if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        {(unit === 'currency' && (
                                            <>{countryToCurrency(userData.country)}</>
                                        )) || <>{unit}</>}{' '}
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </FormGroup>

                    {meta.error === 'errorMessageMax100' && (
                        <Alert color="danger">{t('errorMessageMax100')}</Alert>
                    )}
                </>
            )}
        </Field>
    );
};

const RenderViewNotAuthorized = () => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/dashboard');
    };
    return (
        <Container className="hasFixedHeader">
            <Row className="my-5 sticky-preferences-ctas justify-content-center text-center">
                <Col xs="12" md="6" className="mb-2">
                    <p dangerouslySetInnerHTML={{ __html: t('messageAccessPreference') || '' }} />
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xs="6" md="3">
                    <AutobizButton type="submit" color="primary" block onClick={handleNavigate}>
                        {t('ctaDashboard')}
                    </AutobizButton>
                </Col>
            </Row>
        </Container>
    );
};

const Preferences: React.FC = () => {
    const dispatch = useDispatch();

    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [alertOpen, setAlertOpen] = useState<boolean>(false);

    const { status, data } = useSelector(getPreferencesSelector);
    const { user: userData } = useSelector(getUserSelector);
    const deviceType = useDeviceType();

    const resetValues = () => {
        dispatch(getUserDefaultPreferencesUseCase());
        setSubmitted(false);
        setAlertOpen(false);
    };

    useEffect(() => {
        if (Object.keys(userData).length !== 0 && userData.canEditPreferences)
            dispatch(getUserPreferencesUseCase());
    }, [dispatch, userData]);

    useEffect(() => {
        if (status === 'succeeded' && submitted) setAlertOpen(true);
    }, [dispatch, status, submitted]);

    const onSubmit = async (props: any) => {
        dispatch(updateUserPreferencesUseCase(props));
        setSubmitted(true);
    };

    const validationSchema: Yup.SchemaOf<any> = Yup.object().shape({
        storageCost: Yup.number()
            .required('errorMessageMandatoryField')
            .typeError('errorMessageMustBeANumber' || ''),
        marginEuro: Yup.number()
            .required('errorMessageMandatoryField')
            .typeError('errorMessageMustBeANumber' || ''),
        marginPercentage: Yup.number()
            .max(100, 'errorMessageMax100')
            .required('errorMessageMandatoryField')
            .typeError('errorMessageMustBeANumber' || ''),
        adminCosts: Yup.number()
            .required('errorMessageMandatoryField')
            .typeError('errorMessageMustBeANumber' || ''),
        reviewCosts: Yup.number()
            .required('errorMessageMandatoryField')
            .typeError('errorMessageMustBeANumber' || ''),
        warrantyFee1: Yup.number()
            .required('errorMessageMandatoryField')
            .typeError('errorMessageMustBeANumber' || ''),
        warrantyFee2: Yup.number()
            .required('errorMessageMandatoryField')
            .typeError('errorMessageMustBeANumber' || ''),
        warrantyFee3: Yup.number()
            .required('errorMessageMandatoryField')
            .typeError('errorMessageMustBeANumber' || ''),
        interestRate: Yup.number()
            .max(100, 'errorMessageMax100')
            .required('errorMessageMandatoryField')
            .typeError('errorMessageMustBeANumber' || ''),
        commercialExpenses: Yup.number()
            .max(100, 'errorMessageMax100')
            .required('errorMessageMandatoryField')
            .typeError('errorMessageMustBeANumber' || ''),
    });

    const validate = (values: any): Promise<any> =>
        validationSchema
            .validate(values, { abortEarly: false })
            .then(() => setCanSubmit(true))
            .catch((error: Yup.ValidationError) => {
                const errors: { [value: string]: string } = {};
                error.inner.forEach((e) => {
                    if (e.path) {
                        errors[e.path] = e.message;
                    }
                });
                setCanSubmit(false);

                return errors;
            });

    return (
        <>
            <Header />
            {userData.canEditPreferences === true && (
                <>
                    <div className="col-12 preferencesMessage">
                        {t('messageChangeSettingsPage')}
                    </div>
                    <Container>
                        <div className="mt-4">
                            <AutobizSectionTitle>
                                <AutobizIcon name="Tools" /> {t('tradeInSettingsTitle')}
                            </AutobizSectionTitle>

                            <p className="font-weight-bold">{t('explanationSettingsText')}</p>
                            <p>{t('explanationSettingsSubText')}</p>
                        </div>

                        <Alert
                            color="success"
                            fade
                            isOpen={alertOpen}
                            toggle={() => setAlertOpen(false)}
                        >
                            {t('preferencesUpdated')}
                        </Alert>
                        <Loader status={status}>
                            <Form
                                onSubmit={onSubmit}
                                validate={validate}
                                initialValues={data}
                                render={({ handleSubmit }) => (
                                    <form onSubmit={handleSubmit} className="mt-5">
                                        {preferencesTable.map(({ code, unit }) => {
                                            if (code === 'warrantyFee1') {
                                                return (
                                                    <div key={code}>
                                                        <div className="font-weight-bold mb-2">
                                                            {t('warrantyFee')} :
                                                        </div>
                                                        <PreferenceRow code={code} unit={unit} />
                                                    </div>
                                                );
                                            }

                                            return (
                                                <PreferenceRow key={code} code={code} unit={unit} />
                                            );
                                        })}

                                        {deviceType !== 'mobile' ? (
                                            <Row className="my-5 justify-content-center">
                                                <Col xs="12" md="5" lg="4" className="mb-2">
                                                    <AutobizButton
                                                        type="button"
                                                        color="tertiary"
                                                        block
                                                        onClick={() => resetValues()}
                                                    >
                                                        {t('displayDefaultValues')}
                                                    </AutobizButton>
                                                </Col>
                                                <Col xs="12" md="5" lg="4" className="mb-2">
                                                    <AutobizButton
                                                        type="submit"
                                                        color="primary"
                                                        block
                                                        disabled={!canSubmit}
                                                    >
                                                        {t('valider')}
                                                    </AutobizButton>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <>
                                                <div className="mb-2 mt-5">
                                                    <AutobizButton
                                                        type="button"
                                                        color="tertiary"
                                                        block
                                                        onClick={() => resetValues()}
                                                    >
                                                        {t('displayDefaultValues')}
                                                    </AutobizButton>
                                                </div>
                                                <div className="sticky-preferences-ctas mb-3">
                                                    <AutobizButton
                                                        type="submit"
                                                        color="primary"
                                                        block
                                                        disabled={!canSubmit}
                                                    >
                                                        {t('valider')}
                                                    </AutobizButton>
                                                </div>
                                            </>
                                        )}
                                    </form>
                                )}
                            />
                        </Loader>
                    </Container>
                </>
            )}
            {userData.canEditPreferences === false && <RenderViewNotAuthorized />}
        </>
    );
};

export default withAuthenticationRequired(Preferences, {
    onRedirecting: () => <AuthSpinner />,
});
