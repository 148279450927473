export const countryToCurrency = (country) => {
    switch (country) {
        case 'UK':
            return '£';
        case 'CH':
            return 'CHF';
        default:
            return '€';
    }
};
